import createStore from 'zustand';

import { STEPS } from '../constants';

type StepStoreType = {
  currentStep: STEPS;
  setStep: (step: STEPS) => void;
};

const useStepStore = createStore<StepStoreType>((set) => ({
  currentStep: STEPS.LOGIN,
  setStep: (step: STEPS) => set({ currentStep: step }),
}));

export default useStepStore;
