import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Panel } from '@salesforce/design-system-react';
import Autocomplete from 'react-autocomplete';
import ReactJSON from 'react-json-view';
import classnames from 'classnames';

import { useContent, useEnvironments, useSpaces } from '../api/contentful';
import { useActivity, useConnection } from '../providers/connection-provider';
import { transformMessageModel } from '../util/message';
import useUserStore from '../stores/user-store';

import { MainLayout } from '../layouts/main-layout';

import classes from './home-page.module.css';

export interface HomePageProps {}

export const HomePage: React.FC<PropsWithChildren<HomePageProps>> = () => {
  const connection = useConnection();
  const activity = useActivity();
  const [space, setSpace] = useState('');
  const [environment, setEnvironment] = useState('');
  const [message, setMessage] = useState<any>(null);
  const [query, setQuery] = useState('');
  const secrets = useUserStore();

  console.log('Activity', activity);
  console.log('connection', connection);
  console.log('secrets', secrets);

  const { data: spaces } = useSpaces();
  const { data: environments } = useEnvironments(space);
  const { data: messages } = useContent(space, environment, query);

  useEffect(() => {
    connection?.trigger('updateButton', {
      button: 'next',
      text: 'Save',
    });
  }, [connection]);

  useEffect(() => {
    const handleSave = () => {
      const messages = transformMessageModel(message);
      const payload = { metaData: {}, arguments: { execute: {} }, ...activity };
      console.log('payload', JSON.stringify(payload));
      payload.metaData.isConfigured = true;
      payload.arguments.execute.inArguments = [
        {
          data: {
            appKey: secrets.appKey,
            apiKey: secrets.apiKey,
            messages,
          },
        },
      ];

      console.log('Will save ', JSON.stringify(payload));
      localStorage.removeItem('activity');
      connection?.trigger('updateActivity', payload);
    };

    connection?.on('clickedNext', handleSave);
    return () => connection?.off('clickedNext', handleSave);
  }, [connection, message, activity]);

  const handleSelectSpace = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setSpace(e.target.value);

  const handleSelectEnvironment = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setEnvironment(e.target.value);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(e.target.value);

  const handleSelectMessage = (_val: any, message: any) => {
    setMessage(message);
    setQuery(message.title['en-US']);
  };

  return (
    <MainLayout>
      <div className={classes.container}>
        <Panel className={classnames(classes.panel, classes.selectionPanel)}>
          <div>
            <label htmlFor="space">Space</label>
            <select
              id="space"
              className="slds-select"
              name="space"
              value={space}
              onChange={handleSelectSpace}
            >
              <option value="">Select space</option>
              {spaces?.map((space) => (
                <option key={space.id} value={space.id}>
                  {space.name}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.formControl}>
            <label htmlFor="environment">Environment</label>
            <select
              id="environment"
              className="slds-select"
              name="environment"
              value={environment}
              onChange={handleSelectEnvironment}
              disabled={!environments?.length}
            >
              <option value="">Select environment</option>
              {environments?.map((environment) => (
                <option key={environment.id} value={environment.id}>
                  {environment.name}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.formControl}>
            <label htmlFor="message">Message</label>
            <br />
            <Autocomplete
              getItemValue={(item) => item.id}
              items={messages ?? []}
              renderItem={(item, isHighlighted) => (
                <div
                  className={classes.messageItem}
                  style={{
                    backgroundColor: isHighlighted
                      ? 'lightgray'
                      : 'transparent',
                  }}
                >
                  {item.title['en-US']}
                </div>
              )}
              value={query}
              onChange={handleQueryChange}
              onSelect={handleSelectMessage}
              wrapperStyle={{
                width: '100%',
              }}
              inputProps={{
                className: 'slds-input w-100',
              }}
            />
          </div>
          {message ? (
            <div className={classes.formControl}>
              <p>
                <strong>Json Preview</strong>
              </p>
              <br />
              <ReactJSON
                src={message}
                collapsed
                style={{ backgroundColor: '#f8f8f8', padding: '1rem' }}
              />
            </div>
          ) : null}
        </Panel>
        <Panel className={classnames(classes.panel, classes.previewPanel)}>
          <h1>Preview here</h1>
        </Panel>
      </div>
    </MainLayout>
  );
};
