import React, { PropsWithChildren, useEffect, useState } from 'react';
import Postmonger from 'postmonger';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import { ConnectionProvider } from './providers/connection-provider';

export interface ProvidersProps {}

const connection = new Postmonger.Session();
const queryClient = new QueryClient();

export const Providers: React.FC<PropsWithChildren<ProvidersProps>> = ({
  children,
}) => {
  const [activity, setActivity] = useState(null);

  useEffect(() => {
    connection.on('initActivity', (payload) => {
      console.log('initializing activity', payload);
      if (payload) {
        setActivity(payload);
        localStorage.setItem('activity', JSON.stringify(payload));
      }
    });
  }, []);

  const storedActivity = localStorage.getItem('activity');
  const parsedStoredActivity = storedActivity ? JSON.parse(storedActivity) : {};

  return (
    <ConnectionProvider
      value={{ connection, activity: activity ?? parsedStoredActivity }}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ConnectionProvider>
  );
};
