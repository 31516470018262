import React, { useEffect } from 'react';

import { STEPS } from './constants';
import { LoginPage } from './pages/login-page';
import { HomePage } from './pages/home-page';
import { useConnection } from './providers/connection-provider';
import useStepStore from './stores/step-store';

function App() {
  const { setStep, currentStep } = useStepStore((state) => state);
  const connection = useConnection();

  useEffect(() => {
    connection?.on('gotoStep', (step: { key: STEPS }) => {
      setStep(step.key);
      connection.trigger('ready');
    });
  }, [connection, setStep]);

  useEffect(() => {
    console.log('Doing ready');
    connection?.trigger('ready');
  }, [connection]);

  console.log('currentStep', currentStep);

  if (currentStep === STEPS.LOGIN) return <LoginPage />;
  if (currentStep === STEPS.MAIN) return <HomePage />;
  return null;
}

export default App;
