import createStore from 'zustand';

type UserStoreType = {
  appKey: string | null;
  apiKey: string | null;
  contentfulToken: string | null;
  setSecrets: (data: {
    contentfulToken: string;
    appKey: string;
    apiKey: string;
  }) => void;
};

const useUserStore = createStore<UserStoreType>((set) => ({
  appKey: null,
  apiKey: null,
  contentfulToken: null,
  setSecrets: (data) => set(data),
}));

export default useUserStore;
