import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Spinner } from '@salesforce/design-system-react';
import qs from 'query-string';

import {
  AZURE_AUTH_URL,
  AZURE_CLIENT_ID,
  AZURE_TENANT_ID,
  OAUTH_REDIRECT_URL,
} from '../constants';
import { login } from '../api/auth';
import { contentfulApi } from '../api/contentful';
import { LoginLayout } from '../layouts/login-layout';
import { useConnection } from '../providers/connection-provider';

import classes from './login-page.module.css';
import useUserStore from '../stores/user-store';

export interface LoginPageProps {}

const LOGIN_URL = `${AZURE_AUTH_URL}/${AZURE_TENANT_ID}/oauth2/v2.0/authorize?client_id=${AZURE_CLIENT_ID}&redirect_url=${OAUTH_REDIRECT_URL}&response_type=code&response_mode=query&scope=openid`;

export const LoginPage: React.FC<PropsWithChildren<LoginPageProps>> = () => {
  const connection = useConnection();
  const setSecrets = useUserStore((store) => store.setSecrets);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    connection?.trigger('updateButton', {
      button: 'next',
      visible: false,
    });
  }, [connection]);

  useEffect(() => {
    const qsParams = qs.parse(window.location.search);
    if (qsParams.code && qsParams.session_state) {
      (async () => {
        try {
          setIsError(false);
          const response = await login({
            code: qsParams.code!.toString(),
          });
          console.log('login resp:', response);
          setSecrets({
            appKey: response.appKey,
            apiKey: response.apiKey,
            contentfulToken: response.contentfulPAT,
          });
          contentfulApi.defaults.headers.common.Authorization = `Bearer ${response.contentfulPAT}`;
          connection?.trigger('nextStep');
        } catch {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setIsLoading(false);
    }
  }, [connection]);

  return (
    <LoginLayout>
      <div className="slds-m-top_large slds-text-align_center">
        {isLoading ? (
          <Spinner size="small" />
        ) : (
          <>
            {isError ? <p>Invalid Login</p> : null}
            <a href={LOGIN_URL} className={classes.loginBtn}>
              Login with Telia
            </a>
          </>
        )}
      </div>
    </LoginLayout>
  );
};
