import axios from 'axios';
import { AUTH_LAMBDA_URL } from '../constants';

export async function login(params: { code: string }) {
  const { data } = await axios.post<
    unknown,
    { data: { contentfulPAT: string; appKey: string; apiKey: string } }
  >(AUTH_LAMBDA_URL, params);

  return data;
}
