export function transformLanguageKey(key: string): string {
  const [lang, dialect] = key.split('-');
  if (!dialect) return lang;
  return `${lang}${dialect[0].toUpperCase()}${dialect.slice(1).toLowerCase()}`;
}

export function transformMessageModel(message: any) {
  const result: any = {};
  const languages = Object.keys(message.title);
  languages.forEach((langKey) => {
    const language = transformLanguageKey(langKey);
    result[language] = result[language] || { language, actions: [] };
    result[language].title = message.title[langKey];
    result[language].body = message.body[langKey];

    if (message.metadata?.[langKey]) {
      result[language].metadata = message.metadata[langKey];
    }

    if (message.media?.[langKey]) {
      result[language].media = { ...message.media[langKey] };
    }

    const actions: any[] = Object.values(message.actions ?? {});
    for (const action of actions) {
      if (!action[0].name[langKey] && !action[0].value[langKey]) continue;
      result[language].actions.push({
        name: action[0].name[langKey],
        value: action[0].value[langKey],
      });
    }
  });

  return Object.values(result);
}
