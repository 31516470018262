export enum STEPS {
  LOGIN = 'login-step',
  MAIN = 'message-step',
}

export const CONTENTFUL_API_URL =
  process.env.REACT_APP_CONTENTFUL_API_URL || 'https://api.contentful.com';
export const CONTENTFUL_CLIENT_ID = process.env.REACT_APP_CONTENTFUL_CLIENT_ID;
export const CONTENTFUL_OAUTH_REDIRECT_URL =
  process.env.CONTENTFUL_OAUTH_REDIRECT_URL;
export const AUTH_LAMBDA_URL =
  process.env.REACT_APP_AUTH_LAMBDA_URL || 'http://localhost:7000';

export const AZURE_AUTH_URL = process.env.REACT_APP_CLOUD_INSTANCE;
export const AZURE_TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const AZURE_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const OAUTH_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URI;
