import { Session } from 'postmonger';
import { createContext, useContext } from 'react';

const ConnectionContext = createContext<{
  connection?: Session;
  activity?: any;
}>({});

export const ConnectionProvider = ConnectionContext.Provider;

export const useConnection = () => {
  const context = useContext(ConnectionContext);
  if (!context) {
    throw new Error(
      'Connection Context is undefined, please wrap the application with ConnectionProvider'
    );
  }
  return context.connection;
};

export const useActivity = () => {
  const context = useContext(ConnectionContext);
  if (!context) {
    throw new Error(
      'Connection Context is undefined, please wrap the application with ConnectionProvider'
    );
  }
  return context.activity;
};
