import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { CONTENTFUL_API_URL } from '../constants';

export const contentfulApi = axios.create({
  baseURL: CONTENTFUL_API_URL,
});

function mapCommonResponse(items: any[]) {
  return items.map((item) => ({
    id: item.sys.id,
    name: item.name,
  }));
}

function mapItemsResponse(items: any) {
  return items.map((item: any) => {
    const result: Record<string, any> = { id: item.sys.id };
    Object.keys(item.fields).forEach((key) => {
      result[key] = item.fields[key];
    });
    return result;
  });
}

export async function getSpaces() {
  const { data } = await contentfulApi.get('/spaces');
  return mapCommonResponse(data.items);
}

export function useSpaces() {
  return useQuery(['GET_SPACES'], () => getSpaces());
}

export async function getSpaceEnvironments(space: string) {
  const { data } = await contentfulApi.get(`/spaces/${space}/environments`);
  return mapCommonResponse(data.items);
}

export function useEnvironments(space: string) {
  return useQuery(
    ['GET_ENVIRONMENTS', space],
    () => getSpaceEnvironments(space),
    {
      enabled: Boolean(space),
    }
  );
}

export async function getContent(
  space: string,
  environment: string,
  query: string
) {
  let url = `/spaces/${space}/environments/${environment}/entries`;
  url += '?limit=10';
  url += '&select=sys.id,fields';
  url += query ? `&query=${query}` : '';
  const { data } = await contentfulApi.get(url);
  return mapItemsResponse(data.items);
}

export function useContent(space: string, environment: string, query: string) {
  return useQuery(
    ['GET_CONTENT', space, environment, query],
    () => getContent(space, environment, query),
    {
      enabled: Boolean(space) && Boolean(environment),
    }
  );
}
