import React, { PropsWithChildren } from 'react';

import classes from './login-layout.module.css';

export interface LoginLayoutProps {}

export const LoginLayout: React.FC<PropsWithChildren<LoginLayoutProps>> = ({
  children,
}) => {
  return (
    <main className={classes.main}>
      <header>
        <img src="/image/vascular-logo.png" alt="vascular-logo" />
      </header>
      <section className={classes.content}>
        <div className="w-100">{children}</div>
      </section>
    </main>
  );
};
